<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
  },
  mounted() {

  }
}
</script>

<style>
body,
html,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  /*overflow: hidden;*/
  -webkit-overflow-scrolling: touch;
}

* {
  margin: 0;
  padding: 0;
  font-family: pingfang sc;
}

html, body {
  height: 100%;
}

.content {
  width: 1200px;
  margin: 0 auto;
}

.fl {
  clear: both;
}

.ov {
  overflow: hidden;
}

@media screen and (max-width: 320px) {
  html, body {
    font-size: 9px
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  html, body {
    font-size: 11px
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  html, body {
    font-size: 12px
  }
}
@media screen and (min-width: 415px) and (max-width: 428px) {
  html, body {
    font-size: 13px
  }
}
@media screen and (min-width: 429px) and (max-width: 639px) {
  html, body {
    font-size: 15px
  }
}

@media screen and (min-width: 640px) and (max-width: 719px) {
  html, body {
    font-size: 20px
  }
}

@media screen and (min-width: 720px) and (max-width: 749px) {
  html, body {
    font-size: 22.5px
  }
}

@media screen and (min-width: 750px) and (max-width: 799px) {
  html, body {
    font-size: 23.5px
  }
}

@media screen and (min-width: 800px) {
  html, body {
    font-size: 25px
  }
}
</style>
