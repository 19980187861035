import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import 'animate.css';
const app = createApp(App)

// // 引入elementui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
router.beforeEach((to,from,next)=>{
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        if (to.fullPath.indexOf('mobile')<=0) {
            router.push({name: 'mobile'+to.name,query:to.query})
        }
    } else {
        if (to.fullPath.indexOf('mobile')>=0) {
            router.push({name: to.name.replace('mobile',''),query:to.query})
            window.document.body.style.minWidth = "1200px";
            window.document.html.style.minWidth = "1200px";
        }
    }
    next()
})
app.use(ElementPlus)

app.use(router)

app.mount('#app')