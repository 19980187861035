import {createRouter, createWebHistory} from 'vue-router'

const routerHistory = createWebHistory()
const routes = [
    {
        path: '',
        redirect: '/layout',
        name:'pc'
    },
    {
        path: '/layout',
        name: 'layout',
        component: () =>
            import('@/views/layout.vue'),
        redirect: '/index',
        children:[
            { // 首页
                path: '/index',
                name: 'index',
                component: () =>
                    import('@/views/index.vue')
            }, { // 产品服务
                path: '/services',
                name: 'services',
                component: () =>
                    import('@/views/services.vue')
            }, { // 技术平台
                path: '/platform',
                name: 'platform',
                component: () =>
                    import('@/views/platform.vue')
            }, { // 关于我们
                path: '/aboutUs',
                name: 'aboutUs',
                component: () =>
                    import('@/views/aboutUs.vue')
            }
        ]
    },

    //移动端
    {
        path: '/mobile',
        redirect: '/mobile/layout',
        name:'mobile'
    },
    {
        path: '/mobile/layout',
        name: 'mobileLayout',
        component: () =>
            import('@/views/mobile/layout.vue'),
        redirect: '/mobile/index',
        children:[
            { // 首页
                path: '/mobile/index',
                name: 'mobileindex',
                component: () =>
                    import('@/views/mobile/index.vue')
            }, { // 产品服务
                path: '/mobile/services',
                name: 'mobileservices',
                component: () =>
                    import('@/views/mobile/services.vue')
            }, { // 技术平台
                path: '/mobile/platform',
                name: 'mobileplatform',
                component: () =>
                    import('@/views/mobile/platform.vue')
            }, { // 关于我们
                path: '/mobile/aboutUs',
                name: 'mobileaboutUs',
                component: () =>
                    import('@/views/mobile/aboutUs.vue')
            }
        ]
    },
]
const router = createRouter({
    history: routerHistory,
    routes
})
export default router